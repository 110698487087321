import request from '@/utils/request'

// 发送事件
export function PostEventApi(query) {
  return request({
    url: '/report/fb',
    method: 'post',
    data: query
  })
}

// 请求页面信息
export function GetNovelApi(query) {
  return request({
    url: '/report/book',
    method: 'get',
    params: query
  })
}

// 请求页面信息
export function GetJumpApi(query) {
  return request({
    url: '/report/jump-to',
    method: 'get',
    params: query
  })
}
